const Trekkie = {
  viewedProduct(product, productVariant) {
    if (process.env.NODE_ENV !== 'production') return;

    const productId = Number(new Buffer(product.id.split('__').pop(), 'base64').toString('ascii').split('/').pop());
    const variantId = Number(new Buffer(productVariant.id.split('__').pop(), 'base64').toString('ascii').split('/').pop());

    const trekkiePayload = {
      currency: window.ShopifyAnalytics.meta.currency,
      variantId,
      productId,
      productGid: `gid://shopify/Product/${productId}`,
      name: product.title,
      price: String(productVariant.price),
      sku: productVariant.sku,
      brand: product.vendor,
      variant: productVariant.title,
      category: product.productType,
    };

    window.ShopifyAnalytics.lib.track('Viewed Product', trekkiePayload);
    window.ShopifyAnalytics.lib.track('monorail://trekkie_storefront_viewed_product/1.1', {
      ...trekkiePayload,
      referer: window.location.href,
    });
  },

  viewedProductVariant(product, productVariant) {
    const productId = Number(new Buffer(product.id.split('__').pop(), 'base64').toString('ascii').split('/').pop());
    const variantId = Number(new Buffer(productVariant.id.split('__').pop(), 'base64').toString('ascii').split('/').pop());

    const trekkiePayload = {
      currency: window.ShopifyAnalytics.meta.currency,
      variantId,
      productId,
      productGid: `gid://shopify/Product/${productId}`,
      name: product.title,
      price: String(productVariant.price),
      sku: productVariant.sku,
      brand: product.vendor,
      variant: productVariant.title,
      category: product.productType,
    };

    window.ShopifyAnalytics.lib.track('Viewed Product Variant', trekkiePayload);
  },

  addedProductToCart(product, productVariant) {
    if (process.env.NODE_ENV !== 'production') return;

    const productId = Number(new Buffer(product.id.split('__').pop(), 'base64').toString('ascii').split('/').pop());
    const variantId = new Buffer(productVariant.id.split('__').pop(), 'base64').toString('ascii').split('/').pop();

    const trekkiePayload = {
      variantId,
      productId,
      currency: window.ShopifyAnalytics.meta.currency,
      quantity: String(1),
      price: Number(productVariant.price),
      name: product.title,
      sku: productVariant.sku,
      brand: product.vendor,
      variant: productVariant.title,
      category: product.productType,
      pageType: 'product',
      resourceType: 'product',
      resourceId: productId,
    };

    window.ShopifyAnalytics.lib.track('Added Product', trekkiePayload);
    window.ShopifyAnalytics.lib.track('monorail://trekkie_storefront_track_added_product/1.1', {
      ...trekkiePayload,
      referer: window.location.href,
    })
  }
};

export default Trekkie;
