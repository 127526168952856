import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const ProductViewed = ({ product, productVariant }) => {
  if (process.env.NODE_ENV !== 'production') return <></>;

  const productId = Number(new Buffer(product.id.split('__').pop(), 'base64').toString('ascii').split('/').pop());
  const variantId = Number(new Buffer(productVariant.id.split('__').pop(), 'base64').toString('ascii').split('/').pop());

  const trekkiePayload = {
    currency: '%window.ShopifyAnalytics.meta.currency%',
    variantId,
    productId,
    productGid: `gid://shopify/Product/${productId}`,
    name: product.title,
    price: String(productVariant.price),
    sku: productVariant.sku,
    brand: product.vendor,
    variant: productVariant.title,
    category: product.productType,
    nonInteraction: true,
  };

  return (
    <Helmet
      script={[{
        type: 'text/javascript',
        innerHTML: `
        window.ShopifyAnalytics.lib.track('Viewed Product', ${JSON.stringify(trekkiePayload).replace(/"%/g, '').replace(/%"/g, '')});
        window.ShopifyAnalytics.lib.track('monorail://trekkie_storefront_viewed_product/1.1', ${JSON.stringify({
          ...trekkiePayload,
          referer: '%window.location.href%',
        }).replace(/"%/g, '').replace(/%"/g, '')});
        `,
      }]}
    />
  );
}

ProductViewed.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    productType: PropTypes.string,
  }),
  productVariant: PropTypes.shape({
    id: PropTypes.string,
    price: PropTypes.string,
    sku: PropTypes.string,
    title: PropTypes.string,
  }),
}

export default ProductViewed;
