import React, { useState, useContext, useEffect, useCallback } from 'react';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import RRS from 'react-responsive-select';
import FixedBottom from '~/components/FixedBottom';
import caret from '~/images/select-caret.svg';
import './ReactResponsiveSelect.scss';
import styles from './styles.module.scss';
import Trekkie from '~/lib/Trekkie';

import StoreContext from '~/context/StoreContext';

const ProductForm = ({ product, onChange }) => {
  const {
    options,
    variants,
  } = product
  const initialVariant = variants.filter(v => v.title === '12 Pack').pop();
  const [variant, setVariant] = useState({ ...initialVariant })
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.shopifyId
        )
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [client.product, productVariant.shopifyId]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleOptionChange = (optionIndex, { altered, value }) => {
    if (!altered) return;
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
    onChange(selectedVariant);

    Trekkie.viewedProductVariant(product, selectedVariant);
  }

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, 1)

    Trekkie.addedProductToCart(product, productVariant);
  }

  /*
  Using this in conjunction with a select input for variants
  can cause a bug where the buy button is disabled, this
  happens when only one variant is available and it's not the
  first one in the dropdown list. I didn't feel like putting
  in time to fix this since its an edge case and most people
  wouldn't want to use dropdown styled selector anyways -
  at least if the have a sense for good design lol.
  */
  /*
  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })
    if (match === undefined) return true
    if (match.availableForSale === true) return false
    return true
  }
  */

  /*
  const price = Intl.NumberFormat(undefined, {
    currency: minVariantPrice.currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(variant.price)
  */
  const price = parseFloat(variant.price).toFixed(2);
  return (
    <>
      <h3>${price}</h3>
      <FixedBottom>
        <div className={styles.wrapper}>
          <div className={styles.mobileTwoColumns}>
            <div className={styles.gridLeft}>
              {options.map(({ id, name, values }, index) => (
                <React.Fragment key={id}>
                  <label htmlFor={name}>{name} </label>
                  <RRS
                    key="options"
                    name={name}
                    onChange={(e) => handleOptionChange(index, e)}
                    options={values.map(value => ({
                      text: value,
                      value,
                    }))}
                    selectedValue={variant.selectedOptions[0].value}
                    caretIcon={<SVG src={caret} />}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
          <button
            className={styles.add}
            type="submit"
            disabled={!available || adding}
            onClick={handleAddToCart}
          >
            Add to Cart
          </button>
        </div>
      </FixedBottom>
      {!available && <p>This Product is out of Stock!</p>}
    </>
  )
}

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductForm
