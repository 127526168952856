import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useEmblaCarousel } from 'embla-carousel-react';
import Image from 'gatsby-image';
import SEO from '~/components/seo';
import ProductForm from '~/components/ProductForm';
import { PageType } from '~/components/Trekkie';
import { ProductViewed } from '~/components/Trekkie/Event';
import styles from './styles.module.scss';

const Slide = ({ image, product }) => <div className={styles.carouselImg}><Image
                fluid={image.localFile.childImageSharp.fluid}
                key={image.id}
                alt={product.title}
              /></div>

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct
  const { variants } = product;
  const initialVariant = variants.find(v => v.title === '12 Pack');
  const { images } = product;
  const initialIndex = images.findIndex(image => image.id === initialVariant.image.id);
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    loop: false,
  });

  useEffect(() => {
    if (embla) {
      embla.changeOptions({ startIndex: initialIndex, containScroll: true });
      embla.on('select', () => {
        setCurrentIndex(embla.selectedScrollSnap());
      });
    }
  }, [embla, initialIndex]);

  const handleMouseover = (index) => {
    embla.scrollTo(index);
    setCurrentIndex(index);
  };

  const handleChangeVariant = (variant) => {
    const imageId = variant.image.id;
    const { images } = product;

    const index = images.findIndex(image => image.id === imageId);
    if (index >= 0) {
      embla.scrollTo(index);
      setCurrentIndex(index);
    }
  };

  const productId = Number(new Buffer(product.id.split('__').pop(), 'base64').toString('ascii').split('/').pop());
  return (
    <>
      <PageType type="product" resourceId={productId} />
      <ProductViewed product={product} productVariant={initialVariant} />
      <SEO title={product.title} description={product.description} />
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.gridLeft}>
            <div className={styles.carousel}>
              <EmblaCarouselReact options={{ startIndex: currentIndex, containScroll: true }}>
                <div style={{ display: 'flex' }}>
                {product.images.map(image => (
                  <Slide key={image.id} image={image} product={product} />
                ))}
                </div>
             </EmblaCarouselReact>
           </div>
           <div style={{ textAlign: 'center' }}>
             <div style={{ display: 'inline-block'}}>
               <div className={styles.thumbnails}>
                 {product.images.map((image, index) => (
                   <div key={image.id} className={index === currentIndex ? 'selected' : undefined} onMouseOver={() => handleMouseover(index)}>
                    <div>
                      <Image
                       fluid={image.localFile.childImageSharp.fluid}
                       key={image.id}
                       alt={product.title}
                       loading="lazy"
                       />
                     </div>
                   </div>
                 ))}
               </div>
             </div>
           </div>
          </div>
          <div className={styles.gridRight}>
            <div style={{ padding: "0px 1.0875rem 1.45rem" }}>
              <h1 className={styles.title}>{product.title}</h1>
              <ProductForm product={product} onChange={handleChangeVariant} />
              <div className={styles.description}
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      vendor
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        sku
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
        image {
          id
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default ProductPage
